import { mapGetters } from 'vuex';

export default {
  name: 'ExpiredAutoDealer',
  props: {
    type: String,
  },
  computed: {
    ...mapGetters({
      expiredAutoDealer: 'cabinet/getExpiredAutoDealer',
    }),
    isExpired() {
      return Boolean(Object.keys(this.expiredAutoDealer).length);
    },
  },
  i18n: require('./i18n').default,
};
