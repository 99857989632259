import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

export default {
  name: 'PersonalManager',
  data() {
    return {
      managerInfo: {},
      userPhone: undefined,
      selectedStar: 0,
      reviewText: undefined,
      isSendReview: false,
      isSendOrder: false,
      showPersonalManager: false,
      isShowRecall: false,
    };
  },
  mounted() {
    if (['b2cAutoOrder_calls', 'b2cAutoDealer', 'b2cAutoOrder'].includes(this.segment)) {
      this.personalManager()
      .then((data) => {
        // Приходит тестовый менеджер в случае если в СРМ нет привязки менеджера к юзеру
        if (data.managerInfo && data.managerInfo.name !== 'test') {
          this.managerInfo = data.managerInfo;
          this.managerInfo.phone = this.managerPhoneMask(data.managerInfo.phone);
          this.showPersonalManager = true;
        }
      });
    }
  },
  methods: {
    ...mapActions({
      personalManager: 'cabinet/leftBar/getPersonalManager',
      sendMessage: 'cabinet/leftBar/sendMessage',
    }),
    normalizePhone(phone) {
      return phone ? phone.replace(/[()+\-\s]/g, '') : '';
    },
    managerPhoneMask(phone) {
      const normalizePhone = this.normalizePhone(phone);
      const phonePart = normalizePhone && normalizePhone.match(/(\d{3})(\d{3})(\d{2})(\d{2})$/);
      return phonePart && phonePart.length > 4 ? `(${phonePart[1]}) ${phonePart[2]} ${phonePart[3]} ${phonePart[4]}` : phone;
    },
    phoneMask(phone) {
      const phonePart = phone && phone.match(/(\d{3})(\d{3})(\d{2})(\d{2})$/);
      return phonePart && phonePart.length > 4 ? `(${phonePart[1]}) ${phonePart[2]}-${phonePart[3]}-${phonePart[4]}` : undefined;
    },
    sendReview() {
      const payload = {
        reviewInfo: {
          stars: this.selectedStar,
          text: this.reviewText,
        },
        clientInfo: {
          id: this.userData.userId,
          userName: this.userData.userName,
          phone: this.userPhone,
        },
        managerInfo: {
          name: this.managerInfo.name,
          phone: this.managerInfo.phone
        },
        isReview: true,
      };

      this.sendMessage(payload)
      .then(() => {
        this.isSendReview = true;
      });
    },
    sendOrder() {
      if (this.userPhone) {
        const payload = {
          clientInfo: {
            id: this.userData.userId,
            userName: this.userData.userName,
            phone: this.userPhone,
          },
          managerInfo: {
            name: this.managerInfo.name,
            phone: this.managerInfo.phone
          },
          isOrder: true,
        };

        this.sendMessage(payload)
        .then(() => {
          this.isSendOrder = true;
        });
      }
    },
    showRecall() {
      this.isShowRecall = true;
    },
  },
  watch: {
    userData(val) {
      if (val.userPhones) {
        this.userPhone = this.phoneMask(this.normalizePhone(val.userPhones[0]));
      }
    }
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      segment: 'cabinet/segment',
    }),
    photoUrl() {
      return `https://cdn.riastatic.com/${this.managerInfo.photo}`;
    },
    clickPhone() {
      if (this.managerInfo.phone) {
        // eslint-disable-next-line no-useless-escape
        const normalizePhone = this.normalizePhone(this.managerInfo.phone);
        return `tel:${normalizePhone}`;
      }
    },
    isWorkTime() {
      const now = moment();
      const monday = now.clone().weekday(0);
      const friday = now.clone().weekday(4);
      const isNowWeekday = now.isBetween(monday, friday, undefined, '[]');
      const hourNow = Number(now.format('HH'));
      return hourNow < 18 && hourNow >= 9 && isNowWeekday;
    },
    conditionForHidingTheBlockDuringBrandCamp() {
      const now = moment();
      const brandCampStart = moment(new Date('2021-07-14T00:00:00'));
      const brandCampEnd = moment(new Date('2021-07-18T00:00:00'));

      return now.isBetween(brandCampStart, brandCampEnd, undefined, '[]');
    },
  },
  i18n: require('./i18n').default,
};
