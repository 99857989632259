export default {
    messages: {
        ru: {
            'Пакет «Автодилер» закінчився': 'Пакет «Автодилер» закончился',
            'Пакет «Авто під замовлення» закінчився': 'Пакет «Авто под заказ» закончился',
            'Ваша компанія не відображається': 'Ваша компания не отображается',
            'Продовжити пакет': 'Продлить пакет',
            'Послуга «Дзвінки» недоступна': 'Услуга «Звонки» недоступна',
            'Термін дії пакету': 'Срок действия пакета',
            'Деякі розділи можуть бути недоступними': 'Некоторые разделы могут быть недоступными',
            'Послуга Дзвінки» недоступна.': 'Услуга «Звонки» недоступна.',
            'Робота з деякими розділами обмежена': 'Работа с некоторыми разделами ограничена',
            'Закінчився термін дії пакету': 'Истек срок действия пакета',
        },
        uk: {
            'Пакет «Автодилер» закінчився': 'Пакет «Автодилер» закінчився',
            'Пакет «Авто під замовлення» закінчився': 'Пакет «Авто під замовлення» закінчився',
            'Ваша компанія не відображається': 'Ваша компанія не відображається',
            'Продовжити пакет': 'Продовжити пакет',
            'Послуга «Дзвінки» недоступна': 'Послуга «Дзвінки» недоступна',
			'Термін дії пакету': 'Термін дії пакету',
            'Деякі розділи можуть бути недоступними': 'Деякі розділи можуть бути недоступними',
            'Послуга Дзвінки» недоступна.': 'Послуга Дзвінки» недоступна.',
            'Робота з деякими розділами обмежена': 'Робота з деякими розділами обмежена',
            'Закінчився термін дії пакету': 'Закінчився термін дії пакету',
        }
    }
};
