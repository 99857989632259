export default {
	messages: {
		ru: {
			'зараз на звязку': 'сейчас на связи',
			'буде на звязку': 'будет на связи',
			'працює': 'работает',
			'Робочий час': 'Рабочее время',
			'Ваші персональні дані будуть оброблені та захищені згідно': 'Ваши персональные данные будут обработаны и защищены в соответствии',
			'до': 'с',
			'Політики приватності': 'Политикой приватности',
			'Передзвоніть мені': 'Перезвоните мне',
			'Дякуємо': ' Спасибо',
			'за звернення, ваш менеджер звяжеться з вами найближчим часом': 'за обращение, ваш менеджер свяжется с вами в ближайшее время',
			'Оцініть роботу вашого менеджера': 'Оцените работу вашего менеджера',
			'дзвоніть з': 'звоните с',
			'менеджер звяжеться з вами першого ж робочого дня, як тільки побачить ваше звернення': ' менеджер свяжется с вами в первый же рабочий день, как только увидит ваше обращение',
			'Дякуємо за сигнал, ми все перевіримо й обовязково станемо краще': 'Спасибо за сигнал, мы все проверим и обязательно станем лучше',
			'Дякуємо, працювати з вами — суцільне задоволення': ' Спасибо, работать с вами — сплошное удовольствие',
			'Відправити відгук': 'Отправить отзыв',
			'Що вам подобається, а що варто покращити?': 'Что вам нравится, а что стоит улучшить?',
			'Замовити зворотній дзвінок': 'Заказать обратный звонок',
			'Ваш персональний менеджер': 'Ваш персональный менеджер',
		},
		uk: {
			'зараз на звязку': 'зараз на зв\'язку',
			'буде на звязку': 'буде на зв\'язку',
			'працює': 'працює',
			'Робочий час': 'Робочий час',
			'Ваші персональні дані будуть оброблені та захищені згідно': 'Ваші персональні дані будуть оброблені та захищені згідно',
			'до': 'до',
			'Політики приватності': 'Політики приватності',
			'Передзвоніть мені': 'Передзвоніть мені',
			'Дякуємо': 'Дякуємо',
			'за звернення, ваш менеджер звяжеться з вами найближчим часом': 'за звернення, ваш менеджер зв\'яжеться з вами найближчим часом',
			'Оцініть роботу вашого менеджера': 'Оцініть роботу вашого менеджера',
			'дзвоніть з': 'дзвоніть з',
			'менеджер звяжеться з вами першого ж робочого дня, як тільки побачить ваше звернення': 'менеджер зв\'яжеться з вами першого ж робочого дня, як тільки побачить ваше звернення',
			'Дякуємо за сигнал, ми все перевіримо й обовязково станемо краще': 'Дякуємо за сигнал, ми все перевіримо й обов\'язково станемо краще',
			'Дякуємо, працювати з вами — суцільне задоволення': 'Дякуємо, працювати з вами — суцільне задоволення',
			'Відправити відгук': 'Відправити відгук',
			'Що вам подобається, а що варто покращити?': 'Що вам подобається, а що варто покращити?',
			'Замовити зворотній дзвінок': 'Замовити зворотній дзвінок',
			'Ваш персональний менеджер': 'Ваш персональний менеджер',
		}
	}
};
